body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.signupselect {
  position: relative;
  width: 100%;
  border: none;
  text-align: center;
  border-bottom: 1px solid lightgray;
  margin-top: 1.5rem;
  padding-bottom: 5px;
  color: #757575;
  font-weight: 300;
}

.starsignup {
  position: absolute;
  font-weight: 400;
  right: 0;
  top: 0;
  color: red;
}

.signupselect:focus {
  outline: none;
}

.buy_sunscription {
  font-weight: bold;
}

.new_feature_container {
  position: relative;
  display: inline-flex;
}

.new_feature::after {
  content: 'NEW';
  position: absolute;
  top: -1px;
  right: 2px;
  color: rgb(252, 191, 85);
  font-weight: bold;
  font-size: 10px;
  -webkit-transform: rotate(20deg);
  -moz-transform: rotate(20deg);
  -o-transform: rotate(20deg);
  -ms-transform: rotate(20deg);
  transform: rotate(20deg);
}

.item_list {
  height: 100%;
  overflow: auto;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 80px;
  font-family: monospace;
  color: red;
  width: 100%;
  height: 100px;
}

.popup-container-my {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(196, 196, 196, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
}

.popup-container-my .popup-my {
  width: 280px;
  border-radius: 24px;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 4px 8px rgba(37, 51, 75, 0.08);
  padding: 30px 40px 23px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-container-my .popup-my .close-my {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.popup-container-my .popup-my h2 {
  margin-top: 21px;
  font-weight: bold;
  margin-bottom: 24px;
}

.popup-container-my .popup-my .input,
.popup-container-my .popup-my .input-dropdown {
  margin-bottom: 23px;
  width: 100%;
}
