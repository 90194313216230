.token_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;
  margin-top: 15px;
}

.token_container span {
  font-weight: 500;
  font-size: 20px;
  margin-right: 15px;
}

.token_container button {
  min-width: 160px;
  background-color: #fb3 !important;
  margin: 0;
  border: none !important;
}
