h5 {
  margin: 20px 0px;
}
.card {
  display: flex;
  align-items: center;
  justify-content: center;
}

.swithContainer {
  margin: 10px 0px;
}

.label {
  font-weight: 600;
  margin-bottom: 0px;
}

textarea {
  min-width: 200px;
  width: 70%;
  resize: none;
  padding: 10px;
}

textarea:focus,
input:focus {
  outline: none;
}
