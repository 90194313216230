canvas {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
}

.app__conatainer {
  min-height: 100vh;
}

.card {
  padding: 15px;
}
.form-label {
  margin-top: 5px;
}
.navbar {
  margin-bottom: 15px;
}

.options-icon {
  color: gray;
}
.nav-link {
  color: grey;
}
.log-out-btn {
  cursor: pointer;
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 0;
  padding-left: 5px;
  background-color: transparent;
  border: none;
}

.log-out-btn:active {
  border-color: transparent !important;
  outline-color: transparent !important;
}
.auth-card {
  margin-top: 100px;
  padding-top: 0px;
  max-width: 400px;
  margin: 0 auto;
}
.form-header {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  color: #fff;
  text-align: center;
  margin-top: -3.13rem;
  margin-bottom: 3rem;
  padding: 1rem;
  border-radius: 0.125rem;
  background-color: #2e2e2e;
}

.hover-button:hover {
  background-color: #2e2e2e !important;
  transition: 600ms;
}
.MuiSwitch-thumb:hover {
  border: none !important;
}
.alert-yellow {
  background-color: #f0f0f0;
}
label.active {
  color: grey !important;
}

.logo-div {
  background: url('/LOGO.png') no-repeat;
  background-size: contain;
  max-width: 400px;
  min-height: 100px;
  margin: 0 auto;
  background-position: center;
}
.logo-div-header {
  background: url('/LOGO.png') no-repeat;
  background-size: contain;
  max-width: 400px;
  min-height: 50px;
  margin: 0 auto;
  background-position: center;
}

.preloader {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preloader img {
  width: 150px;
  height: 150px;
}

.pay_button {
  margin: 0;
  margin-top: 20px;
  padding: 10px 35px;
}
