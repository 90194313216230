.active {
  color: black !important;
}

.link {
  font-weight: 400;
  color: #808080;
  list-style: none;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.navbar_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logout {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.disabled {
  opacity: 0.33;
  pointer-events: none;
}

.link:hover {
  color: #808080;
}

@media (max-width: 991px) {
  .navbar_container {
    margin: 5px 0px;
  }
}
