.footer__container {
  width: 100%;
  background-color: #f5f7fa;
  padding: 80px;
  margin-top: 40px;
  display: flex;
}

.footer__logo_container {
  width: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.footer__logo_container img {
  width: 220px;
}

.footer__links_container {
  display: flex;
  width: 66%;
}

.footer__links_item {
  padding: 0px 40px 15px 40px;
  display: flex;
  flex-direction: column;
}

.footer__links_item > div {
  color: #a6abb3;
  font-size: 18px;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.footer__links_item ul {
  padding: 0;
}

.footer__links_item li {
  cursor: pointer;
  list-style: none;
  font-weight: 400;
  margin-bottom: 10px;
}

.footer__links_item li a {
  color: inherit;
}

@media (max-width: 1001px) {
  .footer__container {
    flex-direction: column;
    align-items: center;
  }
  .footer__logo_container {
    margin-bottom: 30px;
    width: 100%;
    justify-content: center;
  }
  .footer__links_container {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }
}
