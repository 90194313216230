.wrap_red {
  width: 100%;
  height: 188px;
  position: absolute;
  top: -8px;
  left: 8px;
  overflow: hidden;
}
.wrap_red:before,
.wrap_red:after {
  content: '';
  position: absolute;
}
.wrap_red:before {
  width: 40px;
  height: 8px;
  right: 80px;
  background: #62d562;
  border-radius: 8px 8px 0px 0px;
}
.wrap_red:after {
  width: 8px;
  height: 40px;
  right: 0px;
  top: 85px;
  background: #62d562;
  border-radius: 0px 8px 8px 0px;
}
.ribbon_red {
  width: 170px;
  height: 40px;
  line-height: 40px;
  position: absolute;
  top: 30px;
  right: -50px;
  z-index: 2;
  font-size: 10px;
  overflow: hidden;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  /* border: 1px dashed; */
  box-shadow: 0 0 0 3px #62d562, 0px 21px 5px -18px rgba(0, 0, 0, 0.6);
  background: #62d562;
  text-align: center;
}

.ribbon_red span {
  font-family: 'Roboto', sans-serif;
  color: white;
  margin-right: 20px;
  font-weight: bold;
}

/* Another color (Green) */
.wrap_green {
  width: 100%;
  height: 188px;
  position: absolute;
  top: -8px;
  left: 8px;
  overflow: hidden;
}
.wrap_green:before,
.wrap_green:after {
  content: '';
  position: absolute;
}
.wrap_green:before {
  width: 40px;
  height: 8px;
  right: 80px;
  background: #008000;
  border-radius: 8px 8px 0px 0px;
}
.wrap_green:after {
  width: 8px;
  height: 40px;
  right: 0px;
  top: 85px;
  background: #008000;
  border-radius: 0px 8px 8px 0px;
}
.ribbon_green {
  width: 170px;
  height: 40px;
  line-height: 40px;
  position: absolute;
  top: 30px;
  right: -50px;
  z-index: 2;
  font-size: 10px;
  overflow: hidden;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  /* border: 1px dashed; */
  box-shadow: 0 0 0 3px #008000, 0px 21px 5px -18px rgba(0, 0, 0, 0.6);
  background: #008000;
  text-align: center;
}

.ribbon_green span {
  font-family: 'Roboto', sans-serif;
  color: white;
  margin-right: 20px;
  font-weight: bold;
}
