.switch_container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.massgen_step_container {
  display: flex;
  margin-bottom: 5px;
}

.select {
  color: gray;
  max-width: 193px;
}

.select option[value=''][disabled] {
  display: none;
}

.massgen_step_container:not(:nth-child(1)) {
  margin-top: 10px;
}

.field_error {
  color: red;
}
.field_error input {
  border-color: red !important ;
}
.field_error select {
  border-color: red !important ;
}

.field_error__text {
  position: absolute;
  bottom: -23px;
  left: 0;
  color: red;
  font-size: 13px;
  font-weight: 400;
}

.information_text {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: normal;
  color: #808080;
}

.massgen_step_counter {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  border-radius: 50%;
  background-color: #ffc107;
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.switch_group {
  display: flex;
  max-width: 600px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.switch_item {
  display: flex;
  width: 200px;
  justify-content: space-between;
  align-items: center;
}

.switch_item__switcher {
  display: flex;
  align-items: center;
  justify-content: center;
}

.create_btn {
  margin-top: 20px;
  background-color: #ffc107;
  border-radius: 7px;
  padding: 9px;
  border: none;
  font-weight: bold;
  max-width: 200px;
  cursor: pointer;
}

.create_btn:hover {
  background-color: #ffce3d;
}
