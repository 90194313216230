.info__container {
  display: flex;
  margin-top: 15px;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2rem;
}

.info__container {
  font-weight: 400;
}

.info__button {
  width: 230px;
  margin: 0.375rem;
  color: white;
  border-radius: 5px !important;
  text-transform: uppercase;
  word-wrap: break-word;
  cursor: pointer;
  border: 0;
  border-radius: 0.125rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0.84rem 2.14rem;
  font-size: 0.81rem;
  background-color: #fb3 !important;
}

.info__button:hover {
  outline: 0;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.button_disable {
  opacity: 0.5;
}

@media screen and (max-width: 993px) {
  .info__container button {
    width: 330px;
  }
}

@media screen and (max-width: 768px) {
  .info__container {
    flex-direction: column;
    font-size: 1.1rem;
  }

  .info__container > p {
    text-align: center;
  }

  .info__container button {
    margin-top: 10px;
    width: 220px;
  }
}
