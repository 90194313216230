.label__container {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.label__container label {
  margin-bottom: 0px;
}

.label__container img {
  margin-left: 5px;
  width: 13px;
}
